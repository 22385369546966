import { useFormik } from 'formik'
import moment from 'moment'
import { KeyboardEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'

import { toast } from 'react-toastify'
import {
  IOptionalFieldsToDisplay,
  IPaymentForms,
  IReadSinglePageCheckout,
  ISinglePageCheckoutAction,
  ISinglePageCheckoutPayment,
  ITemporaryCreditCardData,
  IWriteSinglePageCheckoutData,
} from 'types'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { useBarteScript } from '~/hooks/useBarteScript'
import { useGeolocation } from '~/hooks/useGeolocation'
import { useUserData } from '~/hooks/useUserData'
import { readSinglePageCheckoutInfo, writeSinglePageCheckout } from '~/services/api'
import { getCRID } from '~/utils/getCRID'
import { gtagGenerateLead } from '~/utils/gtag/gtagGenerateLead'
import {
  getLastName,
  hashCard,
  isEmailValid,
  isPhoneValid,
  validateBirthDate,
  validateCEP,
  validateCPF,
  validateFullName,
} from '~/utils/index'
import { removeMask } from '~/utils/removeMask'

const invalidName =
  'O nome inserido parece ser diferente do Nome Completo vinculado à esse CPF na Receita Federal, por favor verifique o nome inserido'

const AddressValidation = {
  zipcode: Yup.string().required(requiredErrorMessages.cep),
  street: Yup.string().required(requiredErrorMessages.street).min(4, errorMessages.street),
  streetNumber: Yup.string().required(requiredErrorMessages.streetNumber),
  state: Yup.string().required(requiredErrorMessages.state),
  city: Yup.string().required(requiredErrorMessages.city).min(3, errorMessages.city),
  district: Yup.string().required(requiredErrorMessages.district).min(3, errorMessages.district),
  complement: Yup.string().notRequired().nullable(),
}

const getOptionalFieldsValidation = ({ birthDate, RG }) => {
  return {
    birthDate: birthDate ? Yup.string().required(errorMessages.requiredField) : Yup.string().optional(),
    RG: RG ? Yup.string().required(requiredErrorMessages.rg).max(16) : Yup.string().optional(),
  }
}

const onePageInitialValues = {
  CPF: '',
  fullName: '',
  email: '',
  confirmEmail: '',
}

const addressOnePageInitialValues = {
  phone: '',
  zipcode: '',
  state: '',
  city: '',
  district: '',
  street: '',
  streetNumber: '',
  complement: '',
  birthDate: '',
  RG: '',
}

export type ISinglePageCheckout = typeof onePageInitialValues & Partial<typeof addressOnePageInitialValues>

const submitText = {
  CreditCard: 'Pagar Agora',
  Boleto: 'Gerar Boleto',
  CourseFinancing: 'Avançar',
  Pix: 'Pagar Agora',
}

export const useSinglePageCheckout = () => {
  const {
    isLoadingData,
    isSendingData,
    setPaymentInformationData,
    chosenPaymentMethod,
    paymentInformationData,
    setChosenPaymentMethod,
    selectedPartnerConditionId,
    selectedUpfront,
  } = useContext(CheckoutContext)

  const { attemptReference, idempotencyKey } = useBarteScript({})

  const { userData } = useUserData()

  const fullNameRef = useRef<HTMLInputElement>(null)
  const disclosureButtonRef = useRef<HTMLButtonElement>(null)

  const [temporaryCreditCardData, setTemporaryCreditCardData] = useState<ITemporaryCreditCardData | null>(null)
  const [optionalFieldsToDisplay, setOptionalFieldsToDisplay] = useState<IOptionalFieldsToDisplay>({
    birthDate: false,
    RG: false,
    phone: false,
  })
  const [isCreditCardValid, setIsCreditCardValid] = useState(false)
  const [imTheCardHolder, setImTheCardHolder] = useState(true)
  const { latitude, longitude, watchPosition } = useGeolocation()

  const shouldShowCourseFinancingForm = useMemo(() => {
    return (
      chosenPaymentMethod === 'CourseFinancing' &&
      paymentInformationData?.response?.paymentMethods?.includes('CourseFinancing') &&
      Object.keys(paymentInformationData?.response?.CourseFinancing || {}).length > 0
    )
  }, [chosenPaymentMethod, paymentInformationData?.response?.CourseFinancing, paymentInformationData?.response?.paymentMethods])

  const proceed = useCallback(
    async (onePageValues: ISinglePageCheckout, _form, isOnBlur) => {
      const crid = getCRID()?.toString()

      crid &&
        window?.smartlook?.('identify', crid, {
          name: onePageValues.fullName,
          email: onePageValues.email,
          CPF: onePageValues.CPF,
          crid,
        })

      const removeMaskPhone = removeMask(onePageValues.phone) as string

      const removeMaskCEP = removeMask(onePageValues.zipcode) as string

      const basicInfo = {
        CPF: onePageValues.CPF,
        fullName: onePageValues.fullName || null,
        email: onePageValues.email || null,
        phone: removeMaskPhone || null,
      }

      const address = {
        zipcode: removeMaskCEP || null,
        state: onePageValues.state || null,
        city: onePageValues.city || null,
        district: onePageValues.district || null,
        street: onePageValues.street || null,
        streetNumber: onePageValues.streetNumber || null,
        complement: onePageValues.complement || null,
        birthDate: onePageValues.birthDate || null,
        RG: onePageValues.RG || null,
      }

      const getAction = (): ISinglePageCheckoutAction => {
        const hasSelectedPartnerCondition =
          ['CreditCard', 'Boleto', 'Pix'].includes(chosenPaymentMethod) ||
          (selectedPartnerConditionId && selectedUpfront) ||
          false

        if (isOnBlur) return 'register'

        if (hasSelectedPartnerCondition) return 'payment'

        return 'simulation'
      }

      const action = getAction()

      const getPaymentData = async (): Promise<ISinglePageCheckoutPayment> => {
        if (action !== 'payment') return

        if (chosenPaymentMethod === 'CreditCard') {
          watchPosition()

          const hash = await hashCard({
            cardNumber: temporaryCreditCardData.cardNumber,
            cvv: temporaryCreditCardData.cardCvv,
            expirationDate: temporaryCreditCardData.cardExpireDate,
            holderName: temporaryCreditCardData.cardName,
          })

          const userAgent = navigator ? navigator.userAgent : ''

          return {
            paymentMethod: 'CreditCard' as IPaymentForms,
            PartnerConditionId: Number(temporaryCreditCardData.partnerConditionId),
            creditCardInformation: {
              hash,
              holder: {
                fullName: imTheCardHolder ? onePageValues.fullName : temporaryCreditCardData.cardName,
                CPF: imTheCardHolder ? onePageValues.CPF : temporaryCreditCardData.holderCPF,
              },
            },
            device: {
              geolocation: {
                latitude,
                longitude,
              },
              userAgent: userAgent,
            },
            idempotencyKey,
            attemptReference,
          }
        }

        if (chosenPaymentMethod === 'Boleto') {
          return {
            paymentMethod: 'Boleto' as IPaymentForms,
            PartnerConditionId: Number(paymentInformationData.response.Boleto.paymentConditions[0].PartnerConditionId),
          }
        }

        if (chosenPaymentMethod === 'CourseFinancing') {
          return {
            paymentMethod: 'CourseFinancing' as IPaymentForms,
            PartnerConditionId: selectedPartnerConditionId,
            upfrontAmountInPercent: selectedUpfront,
          }
        }

        if (chosenPaymentMethod === 'Pix') {
          return {
            paymentMethod: 'Pix' as IPaymentForms,
            PartnerConditionId: Number(paymentInformationData.response.Pix.paymentConditions[0].PartnerConditionId),
          }
        }
      }

      const paymentData = await getPaymentData()

      const data: IWriteSinglePageCheckoutData = {
        action,
        ...basicInfo,
        ...(action === 'payment' && { ...paymentData }),
        ...(chosenPaymentMethod === 'CourseFinancing' && { ...address }),
      }

      const response = await writeSinglePageCheckout(data)

      // string means there was an error
      if (typeof response === 'string') {
        const isInvalidName = response.includes(invalidName)

        if (isInvalidName) {
          handleInvalidFullName()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      temporaryCreditCardData,
      chosenPaymentMethod,
      paymentInformationData,
      latitude,
      longitude,
      watchPosition,
      selectedPartnerConditionId,
      selectedUpfront,
      imTheCardHolder,
    ],
  )

  const {
    values,
    errors,
    touched,
    handleSubmit,
    isValid,
    setFieldValue,
    validateForm,
    setFieldTouched,
    isSubmitting,
    setFieldError,
  } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      ...onePageInitialValues,
      ...(chosenPaymentMethod === 'CourseFinancing' && { ...addressOnePageInitialValues }),
    },
    validationSchema: Yup.object({
      CPF: Yup.string().trim().required(requiredErrorMessages.cpf),
      fullName: Yup.string().trim().required(requiredErrorMessages.fullName),
      phone: optionalFieldsToDisplay?.phone ? Yup.string().trim().required(requiredErrorMessages.phone) : Yup.string().trim(),
      email: Yup.string().trim().email(requiredErrorMessages.emailInvalid).required(requiredErrorMessages.emailInvalid),
      confirmEmail: Yup.string()
        .trim()
        .email(requiredErrorMessages.emailInvalid)
        .oneOf([Yup.ref('email'), null], requiredErrorMessages.confirmEmail)
        .required(requiredErrorMessages.confirmEmail),
      ...(chosenPaymentMethod === 'CourseFinancing' && {
        ...AddressValidation,
        ...getOptionalFieldsValidation(optionalFieldsToDisplay),
      }),
    }),
    onSubmit: (values, form) => proceed(values, form, false),
    validate: (fields: ISinglePageCheckout) => {
      const fieldsArray = Object.keys(fields) as Array<keyof ISinglePageCheckout>
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'CPF' && fields[cur] && fields[cur].length === 14 && !validateCPF(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.invalidCPF,
          }
        }

        if (cur === 'fullName' && fields[cur] && !validateFullName(fields[cur])) {
          const lastName = getLastName(fields[cur])[0]
          if (!fields[cur].includes(' ') || (lastName && lastName.length < 2) || !lastName) {
            return {
              ...prev,
              [cur]: errorMessages.fullName,
            }
          }

          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'email' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        if (cur === 'confirmEmail' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.confirmEmail,
          }
        }

        if (chosenPaymentMethod === 'CourseFinancing') {
          if (cur === 'phone' && fields[cur] && !isPhoneValid(fields[cur])) {
            if (fields[cur].length === 1) {
              return {
                ...prev,
                [cur]: requiredErrorMessages.phone,
              }
            }
            return {
              ...prev,
              [cur]: errorMessages.phone,
            }
          }

          if (cur === 'zipcode' && fields[cur] && !validateCEP(fields[cur]) && removeMask(fields[cur]).length !== 8) {
            return {
              ...prev,
              [cur]: errorMessages.cep,
            }
          }

          if (cur === 'birthDate' && fields[cur] && !validateBirthDate(fields[cur])) {
            const wrongFormat = fields[cur].length < 10

            if (moment().diff(moment(fields[cur], 'DD/MM/YYYY'), 'years') <= 16 && !wrongFormat) {
              return {
                ...prev,
                [cur]: 'Você deve ter mais de 16 anos de idade',
              }
            }

            return {
              ...prev,
              [cur]: wrongFormat ? 'Digite a data no formato dd/mm/aaaa' : errorMessages.birthDate,
            }
          }
        }

        return { ...prev }
      }, {})
    },
  })

  function handleInvalidFullName() {
    const isDisclosureButtonExpanded = disclosureButtonRef?.current?.getAttribute('aria-expanded') === 'true'

    if (!isDisclosureButtonExpanded) {
      disclosureButtonRef?.current?.click()
    }

    setFieldError('fullName', invalidName)

    window.scrollTo({
      top: fullNameRef?.current?.offsetTop - 100,
      behavior: 'smooth',
    })

    toast.error(invalidName, {
      toastId: invalidName,
      autoClose: 10000,
    })
  }

  const populateInputs = useCallback(
    (response: Partial<IReadSinglePageCheckout['response']>) => {
      Object.keys(response).forEach((key) => {
        if (key === 'email' && response?.email) {
          setFieldValue('confirmEmail', response.email)
          setFieldTouched('confirmEmail')
        }

        response[key] && setFieldValue(key, response[key] || '')
        response[key] && setFieldTouched(key)
      })

      validateForm()

      // scroll to first empty input or payment methods
      setTimeout(() => {
        const headerOffset = 240

        const input = document.getElementsByTagName('input') as HTMLCollectionOf<HTMLInputElement>
        let emptyInputFound = false
        for (let i = 0, n = input.length; i < n; i = i + 1) {
          if (!input[i].value) {
            window.scrollTo({
              top: input[i].offsetTop - headerOffset,
              behavior: 'smooth',
            })

            input[i].focus()
            emptyInputFound = true

            break
          }
        }

        if (!emptyInputFound) {
          const paymentMethods = document.getElementById('payment-methods-text')
          if (paymentMethods) {
            window.scrollTo({
              top: paymentMethods.offsetTop - headerOffset / 4,
              behavior: 'smooth',
            })
          }
        }
      }, 100)
    },
    [setFieldValue, setFieldTouched, validateForm],
  )

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  useEffect(() => {
    const getSinglePageCheckoutInfo = async () => {
      const result = await readSinglePageCheckoutInfo()
      // todo: change GET request to new schema

      if (typeof result != 'string') {
        const getFullUserData = () => {
          if (userData) {
            Object.keys(result?.response).forEach((key) => {
              if (result?.response[key] === '' || result?.response[key] === null) {
                delete result?.response[key]
              }
            })

            return {
              ...userData,
              ...result?.response,
            }
          }
          return result?.response
        }

        const fullUserData = getFullUserData()

        populateInputs(fullUserData)
        setPaymentInformationData(result)
        setOptionalFieldsToDisplay(
          result?.response?.optionalFieldsToDisplay || {
            birthDate: false,
            RG: false,
            phone: false,
          },
        )

        if (result?.response?.isNameInvalid) {
          setTimeout(() => {
            handleInvalidFullName()
          }, 500)
        }

        gtagGenerateLead()
      }
    }

    getSinglePageCheckoutInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitButtonText = useMemo(() => {
    return submitText[chosenPaymentMethod || 'CourseFinancing']
  }, [chosenPaymentMethod])

  const reason = useMemo(() => {
    return paymentInformationData?.response?.reason
  }, [paymentInformationData?.response?.reason])

  const currentPaymentMethod = useMemo(() => {
    if (reason || !paymentInformationData?.response?.paymentMethods?.length) {
      return null
    }

    const paymentMethodsExt = paymentInformationData?.response?.paymentMethodsExt || []
    const activeMethods: IPaymentForms[] = ['CourseFinancing', 'CreditCard', 'Boleto', 'Pix']

    for (const method of activeMethods) {
      if (paymentMethodsExt?.find((ext) => ext.method === method)?.active) {
        return method
      }
    }

    return null
  }, [paymentInformationData?.response?.paymentMethods, reason, paymentInformationData?.response?.paymentMethodsExt])

  useEffect(() => {
    !chosenPaymentMethod && setChosenPaymentMethod(currentPaymentMethod)

    if (reason) {
      setChosenPaymentMethod(null)
    }

    if (
      chosenPaymentMethod &&
      !paymentInformationData?.response?.paymentMethodsExt?.find((ext) => ext.method === chosenPaymentMethod)?.active
    ) {
      setChosenPaymentMethod(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentMethod, reason])

  const isButtonDisabled = useMemo(() => {
    if (chosenPaymentMethod === 'CreditCard') {
      return !isCreditCardValid || !isValid
    }

    if (shouldShowCourseFinancingForm) {
      // zero upfront shouldnt be allowed: https://provi-workspace.slack.com/archives/C068GV6PASK/p1702492650733979
      return !selectedPartnerConditionId || !selectedUpfront
    }

    return !isValid || !chosenPaymentMethod
  }, [
    chosenPaymentMethod,
    isCreditCardValid,
    isValid,
    selectedPartnerConditionId,
    selectedUpfront,
    shouldShowCourseFinancingForm,
  ])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPaymentMethod])

  const handleOnBlurSubmission = useCallback(
    (field: keyof ISinglePageCheckout) => {
      if (errors[field] && !isSubmitting) {
        return
      }

      proceed(values, null, true)
    },
    [errors, isSubmitting, proceed, values],
  )

  // todo: create new button to create courseFinancing simulation loading  and show courseFinancing screen

  const firstName = values?.fullName?.split?.(' ')?.[0] || ''

  const shouldShowReason = useMemo(() => {
    return !chosenPaymentMethod && paymentInformationData?.response?.reason?.length
  }, [chosenPaymentMethod, paymentInformationData?.response?.reason])

  return {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isLoadingData,
    isSendingData,
    chosenPaymentMethod,
    temporaryCreditCardData,
    setTemporaryCreditCardData,
    submitButtonText,
    setIsCreditCardValid,
    isButtonDisabled,
    handleOnBlurSubmission,
    optionalFieldsToDisplay,
    shouldShowCourseFinancingForm,
    firstName,
    shouldShowReason,
    reason,
    imTheCardHolder,
    setImTheCardHolder,
    fullNameRef,
    disclosureButtonRef,
  }
}
